import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Services from './components/Services';
import Pricing from './components/Pricing';
import ContactUs from './components/ContactUs';
import Login from './components/Login';
import Setup from './components/Dashboard/Setup';
import Tests from './components/Dashboard/Tests';
import Reports from './components/Dashboard/Reports';
import Insights from './components/Dashboard/Insights';
import AuthGuard from './components/AuthGuard';
import CookieConsentPopup from './components/CookieConsent/CookieConsentPopup';
import { StorageUtil } from './utils/storageUtil';
import DashboardWrapper from './components/DashboardWrapper';

// Policy components
import PrivacyPolicy from './components/Footer/PrivacyPolicy';
import CookiePolicy from './components/Footer/CookiePolicy';
import TermsOfService from './components/Footer/TermsOfService';
import Disclaimer from './components/Footer/Disclaimer';

// NotFound component
import NotFound from './components/NotFound';

function App() {
  const { checkCookieConsentGiven } = StorageUtil();
  const location = useLocation();

  useEffect(() => {
    if (!location.hash) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/login" element={<AuthGuard><Login /></AuthGuard>} />
        <Route path="/dashboard/*" element={<AuthGuard><DashboardWrapper /></AuthGuard>}>
          <Route path="insights" element={<Insights />} />
          <Route path="setup" element={<Setup />} />
          <Route path="tests" element={<Tests />} />
          <Route path="reports" element={<Reports />} />
        </Route>
        {/* Policy routes */}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        {/* NotFound route */}
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
      {!checkCookieConsentGiven() && <CookieConsentPopup />}
    </div>
  );
}

export default App;