import React from 'react';
import './Policy.css';

const PrivacyPolicy = () => {
  return (
    <div className="policy-container">
      <h1>Privacy Policy</h1>
      <p>Welcome to SourceTrooper. We are committed to protecting your privacy and ensuring the security of your data throughout our testing processes. This Privacy Policy explains how we collect, use, disclose, and protect information gathered from our clients' applications and websites during testing.</p>
      
      <h2>Information Collection</h2>
      <p><strong>Data Under Testing:</strong> We only test applications and websites as provided by our clients for deployment to end-users. This means we do not require, collect, or store personal information, customer data, or business secrets that are not intended for public release.</p>
      <p><strong>Automated Testing:</strong> During automated testing processes, our systems may temporarily store technical data necessary for testing purposes, such as application configurations, performance metrics, and testing logs. This data is used solely for the purpose of ensuring the functionality and performance of your applications.</p>
      
      <h2>Use of Information</h2>
      <p><strong>Testing Purposes:</strong> The information collected is strictly used for conducting comprehensive testing of your applications. We do not use this data for any other purposes, including marketing or advertising.</p>
      <p><strong>Data Minimization:</strong> We adhere to the principle of data minimization, collecting only the information necessary for testing and promptly deleting it after testing is complete.</p>
      
      <h2>Information Security</h2>
      <p><strong>Data Integrity:</strong> We employ robust security measures to protect the integrity of your data during testing. This includes encryption, access controls, and secure storage practices.</p>
      <p><strong>No Data Sharing:</strong> We do not share, sell, or disclose any information collected during testing to third parties unless required by law or with your explicit consent.</p>
      
      <h2>Limitation of Liability</h2>
      <p><strong>Testing Limitations:</strong> While we strive to provide thorough testing, it is important to note that testing cannot guarantee the detection of all bugs or issues. Our testing processes are conducted to industry standards, but we acknowledge the inherent limitations of human testing.</p>
      <p><strong>No Warranty:</strong> We do not provide any warranties regarding the performance or functionality of your applications beyond our testing scope. Issues that arise post-deployment which were not within our testing scope may require additional testing or debugging.</p>
      
      <h2>Compliance</h2>
      <p><strong>Industry Standards:</strong> Our practices adhere to industry standards for software testing and data protection, including GDPR principles for data privacy and security.</p>
      
      <h2>Contact Us</h2>
      <p><strong>Questions:</strong> For any questions or concerns regarding our Privacy Policy or data handling practices, please contact us using the information provided in the Contact Us section of our website.</p>
    </div>
  );
};

export default PrivacyPolicy;
