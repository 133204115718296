import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-background"></div>
      <div className="footer-content">
        <div className="footer-section footer-logo">
          <img src="/images/st-logo-black.png" alt="Logo" className="footer-logo-img" />
          <p className="company-name">SourceTrooper</p>
          <p className="footer-copyright">&copy; 2024 SourceTrooper. <br/> All rights reserved.</p>
        </div>
        <div className="footer-section quick-links">
          <h2>Quick Links</h2>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/services">Services</Link></li>
            <li><Link to="/pricing">Pricing</Link></li>
            <li><Link to="/contact-us">Contact Us</Link></li>
          </ul>
        </div>
        <div className="footer-section legal">
          <h2>Legal</h2>
          <ul>
            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
            <li><Link to="/terms-of-service">Terms of Service</Link></li>
            <li><Link to="/cookie-policy">Cookie Policy</Link></li>
            <li><Link to="/disclaimer">Disclaimer</Link></li>
          </ul>
        </div>
        <div className="footer-section resources">
          <h2>Resources</h2>
          <ul>
            <li><Link to="/contact-us#faq-section">FAQs</Link></li>
            <li><Link to="/pricing#comparison-section">InHouse QA vs Us</Link></li>
            <li><Link to="/#how-section">How We Work</Link></li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
