import React from 'react';
import './Services.css';

function Services() {
  return (
    <div className="services">
      <div className="section services-section">
        <h1>Our Services</h1>
        <p>We understand that quality software is the backbone of your business. That's why we offer a comprehensive suite of testing services designed to ensure your web and mobile applications not only meet but exceed industry standards.</p>
      </div>
      <div className="service-section">
        <div className="service-card">
          <div className="service-card-title">
            <div className="service-card-image-container">
              <img
                src="/images/web-testing.jpg"
                alt="Web Testing"
                loading="lazy"
              />
            </div>
            <h3>Web Application Testing</h3>
          </div>
          <ul>
          <br/>
            <li><strong>Functionality and Usability Testing:</strong> Experience seamless interaction across all major browsers, ensuring your application is intuitive and user-friendly.</li>
            <br/>
            <li><strong>Performance Testing:</strong> Boost your application's speed and responsiveness, delivering a swift experience to your users.</li>
            <br/>
            <li><strong>Accessibility Testing:</strong> Guarantee inclusivity with applications that are accessible to all users, adhering to WCAG guidelines.</li>
            <br/>
            <li><strong>Security Testing:</strong> Protect user data with rigorous security protocols, preventing breaches and building trust.</li>
            <br/>
            <li><strong>Load Testing:</strong> Prepare for peak traffic with scalable solutions that maintain performance under pressure.</li>
            <br/>
            <li><strong>API Testing:</strong> Ensure smooth integration and reliable communication between services with comprehensive API checks.</li>
            <br/>
            <li><strong>Automated Regression Testing:</strong> Quickly identify any disruptions in functionality after updates with automated tests.</li>
            <br/>
            <li><strong>Compatibility Testing:</strong> Confirm that your application operates consistently across different devices, OS, and networks.</li>
            <br/>
          </ul>
        </div>
        <div className="service-card">
          <div className="service-card-title">
            <div className="service-card-image-container">
              <img
                src="/images/mobile-testing.jpg"
                alt="Mobile Testing"
                loading="lazy"
              />
            </div>
            <h3>Mobile Application Testing</h3>
          </div>
          <ul>
          <br/>
            <li><strong>Functionality and Usability Testing:</strong> Deliver an impeccable user experience with thorough testing of your mobile app's features and usability.</li>
            <br/>
            <li><strong>Performance Testing:</strong> Ensure your app runs smoothly on all devices, providing a lag-free experience regardless of platform.</li>
            <br/>
            <li><strong>Browser Testing:</strong> Validate the performance of your web applications on mobile browsers for a truly responsive design.</li>
            <br/>
            <li><strong>Automated UI Testing:</strong> Automate the validation of user interfaces to quickly catch any visual or functional issues.</li>
            <br/>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Services;
