// reducers/index.js

import { combineReducers } from 'redux';
import authReducer from './authReducer'; // Import your authentication reducer

const rootReducer = combineReducers({
  auth: authReducer, // Add your authentication reducer to the root reducer
  // Add other reducers here if you have them
});

export default rootReducer;
