import React, { useState, useEffect } from 'react';
import './Insights.css';
import { useClientData } from '../../contexts/clientDataContext';
import LoadCard from './InisightCards/LoadCard';
import ZapCard from './InisightCards/ZapCard';
import PlaywrightCard from './InisightCards/PlaywrightCard';

const Insights = () => {
  const {insights, clientProfile } = useClientData();
  const [localInsights, setInsights] = useState(insights);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (clientProfile?.apiToken) {
      fetchInsights();
    }
  }, [clientProfile, apiUrl]);

  const fetchInsights = async () => {
    try {
      const response = await fetch(`${apiUrl}/insight/fetch/all`, {
        headers: { 'x-api-key': clientProfile.apiToken }
      });
      const data = await response.json();
      setInsights(data);
    } catch (error) {
      console.error('Error fetching insights data:', error);
    }
  };

  const insightConfigs = [
    { key: 'load', component: LoadCard, title: 'Load Insights' },
    { key: 'zap', component: ZapCard, title: 'Zap Insights' },
    { key: 'frontend-report', component: PlaywrightCard, title: 'Frontend Test Insights' },
    { key: 'security-report', component: PlaywrightCard, title: 'Security Test Insights' }
  ];

  const renderInsightCard = (config) => {
    const { key, component: Component, title } = config;
    const data = localInsights && localInsights[key];
    if (!data) return null; // Skip rendering if data is not available
    return (
      <div key={key} className="insight-card">
        <Component title={title} data={data} />
      </div>
    );
  };

  // Check if there are any valid cards to render
  const hasInsights = insightConfigs.some(config => localInsights && localInsights[config.key]);

  return (
    <div className="full-page-container">
      <div className="insights-page-container">
        <div className="insights-header">
          <h1>Insights</h1>
        </div>
        <div className="insights-list">
          {insightConfigs.map(config => renderInsightCard(config))}
          {!hasInsights && <div>No insights available</div>}
        </div>
      </div>
    </div>
  );
};

export default Insights;
