import React, { useState, useEffect } from 'react';
import './Pricing.css';
import ScrollIndicator from './ScrollIndicator';

const sections = [
  { id: 'feature-section', label: 'Our Pricing' },
  { id: 'comparison-section', label: 'Why Choose Us?' }
];

const features = [
  'Performance Test', 'Load Test', 'Login', 'Cart', 'Security', 
  'Payment', 'Search Functionality', 'User Registration', 'Data Export',
  'API Integration', 'Multi-language Support', 'Mobile Responsiveness',
  'Cross-browser Testing', 'Accessibility', 'Database Interaction'
];

const Pricing = () => {
  const [highlightedFeatures, setHighlightedFeatures] = useState([]);

  useEffect(() => {
    const highlightRandomFeatures = () => {
      const shuffled = [...features].sort(() => 0.5 - Math.random());
      setHighlightedFeatures(shuffled.slice(0, 5));
    };
    highlightRandomFeatures();
    const interval = setInterval(highlightRandomFeatures, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <ScrollIndicator sections={sections} />
      <div className="pricing">
        <div className="section pricing-section" id="pricing-section">
        <h2>Optimized QA Solutions at Competitive Rates</h2>
        <p>Our pricing is designed to scale with your needs, ensuring you get the most value whether you're a startup or a large enterprise.</p>
        </div>
        <div className="section feature-section" id="feature-section">
          <h2>Flexible Pricing for What Matters</h2>
          <br/>
          <div className="feature-pills">
            {features.map((feature, index) => (
              <div 
                key={index} 
                className={`pill ${highlightedFeatures.includes(feature) ? 'highlighted' : ''}`}
              >
                {feature}
              </div>
            ))}
          </div>
          <div className="feature-details">
            <br/>
            <br/>
            <div className="feature-info">
              <div>
              <p><strong>More for less</strong></p>
                <p>$200 per feature/month. Every feature test includes 3 testing scenarios.</p>
              </div>
              <div>
              <p><strong>Peace of Mind, all the time</strong></p>
                <p>Unlimited test executions whenever you want, how you want - via Portal or API.</p>
              </div>
              <div>
                <p><strong>Time saving Insights</strong></p>
                <p>Download comprehensive reports or save time by viewing highlighted insights.</p>
              </div>
              <div>
              <p><strong>Your success is our success</strong></p>
                <p>If any feature is not automated or needs additional support, we will test this manually.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="section comparison-section" id="comparison-section">
          <h2>Invest in Excellence, Save on Costs</h2>
          <br/>
          <div className="comparison-cards">
            <div className="comparison-card">
              <h3>In-House QA</h3>
              <hr/>
              <ul>
                <li><strong>Costs:</strong> Annual Salary $48,000 - $72,000, Onboarding and Training</li>
                <br/>
                <li><strong>Infrastructure & Tools:</strong> Significant initial investment for tools and resources</li>
                <br/>
                <li><strong>Availability:</strong> Limited to work hours, idle time between releases</li>
                <br/>
                <li><strong>Commitment:</strong> Based on contract and Job specifications</li>
              </ul>
            </div>
            <div className="comparison-card">
              <h3>SourceTrooper</h3>
              <hr/>
              <ul>
                <li><strong>Costs:</strong> Pay for what you need, no idle costs</li>
                <br/>
                <li><strong>Infrastructure & Tools:</strong> No Significant investment, Get Started in 48hrs</li>
                <br/>
                <li><strong>Availability:</strong> Active round-the-clock, scalable on demand</li>
                <br/>
                <li><strong>Commitment:</strong> Month to Month</li>
              </ul>
            </div>
          </div>
          <br/>
          <br/>
          <br/>
        </div>
        <div className="cta-section" id="cta-section">
          <h2>Ready to ensure top-notch quality for your projects?</h2>
          <p>Contact us today to get started!</p>
          <button className="contact-button" onClick={() => window.location.href='/contact-us'}>Contact Us</button>
        </div>
      </div>
    </>
  );
}

export default Pricing;