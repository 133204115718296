const initialState = {
  isAuthenticated: localStorage.getItem('isAuthenticated') === 'true',
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      localStorage.setItem('isAuthenticated', 'true');
      return {
        ...state,
        isAuthenticated: true,
      };
    case 'LOGOUT':
      localStorage.setItem('isAuthenticated', 'false');
      localStorage.removeItem('accessToken');
      return {
        ...state,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};

export default authReducer;
