import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import './ZapCard.css';

const ZapCard = ({ title, data }) => {
  const COLORS = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#F7464A'];
  const { High, Medium, Low, Informational, 'False Positives': FalsePositives } = data;

  const barData = [
    { name: 'High', High },
    { name: 'Medium', Medium },
    { name: 'Low', Low },
    { name: 'Informational', Informational },
    { name: 'False Positives', FalsePositives }
  ];

  return (
    <div className="card">
      <h3>{title}</h3>
      <div className="chart-container">
        <ResponsiveContainer width="100%" height={250}>
          <BarChart data={barData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="High" stackId="a" fill={COLORS[0]} />
            <Bar dataKey="Medium" stackId="a" fill={COLORS[1]} />
            <Bar dataKey="Low" stackId="a" fill={COLORS[2]} />
            <Bar dataKey="Informational" stackId="a" fill={COLORS[3]} />
            <Bar dataKey="False Positives" stackId="a" fill={COLORS[4]} />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div className="summary">
        {barData.map((entry, index) => (
          <div className="summary-item" key={index}>
            <span className="color-box" style={{ backgroundColor: COLORS[index % COLORS.length] }}></span>
            {entry.name}: {Object.values(entry)[1]}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ZapCard;