import React from 'react';
import { ClientDataProvider } from '../contexts/clientDataContext';
import Dashboard from './Dashboard';

const DashboardWrapper = () => {
  return (
    <ClientDataProvider>
      <Dashboard />
    </ClientDataProvider>
  );
};

export default DashboardWrapper;
