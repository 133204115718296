import React, { createContext, useState, useEffect, useContext } from 'react';
import { StorageUtil } from '../utils/storageUtil';
import { useDispatch } from 'react-redux';
import { logout } from '../actions/authActions';

const clientDataContext = createContext();
export const useClientData = () => useContext(clientDataContext);

export const ClientDataProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [clientProfile, setClientProfile] = useState(null);
  const [tests, setTests] = useState({});
  const [reports, setReports] = useState({});
  const [insights, setInsights] = useState({});
  const { getData, setData, removeData } = StorageUtil();
  const apiUrl = process.env.REACT_APP_API_URL;
  const userData = getData('userData');
  const userId = userData ? userData.id : null;

  const tokenRefreshQueue = []; // Queue for managing token refresh requests
  let isRefreshingToken = false; // Flag to track if token refresh is in progress

  const refreshToken = async () => {
    try {
      const storedRefreshToken = getData('refreshToken');
      if (!storedRefreshToken) {
        handleTokenRefreshError('No refresh token available');
        return;
      }

      const response = await fetch(`${apiUrl}/auth/refresh-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: storedRefreshToken }),
      });

      if (response.ok) {
        const { accessToken, refreshToken } = await response.json();
        setData('accessToken', accessToken);
        setData('refreshToken', refreshToken);
        setData('apiKey', accessToken); // Update API key in storage
        console.log('Token refreshed successfully');
      } else {
        handleTokenRefreshError('Failed to refresh token');
      }
    } catch (error) {
      handleTokenRefreshError('Error refreshing token:', error);
    } finally {
      isRefreshingToken = false; // Reset token refresh in progress flag
      // Process next token refresh request in the queue, if any
      if (tokenRefreshQueue.length > 0) {
        const nextRequest = tokenRefreshQueue.shift();
        nextRequest();
      }
    }
  };

  const handleTokenRefreshError = async (message, error) => {
    console.error(`ClientDataProvider: ${message}`, error);
    await removeBackendCaches();

    removeData('isAuthenticated', 'localStorage');
    removeData('accessToken');
    removeData('refreshToken');
    removeData('apiKey');
    removeData('userData');
    dispatch(logout());
    window.location.href = '/login';
  };

  const removeBackendCaches = async () => {
    let token = getData('accessToken');
    const headers = {
      'Authorization': `Bearer ${token}`
    };

    const cacheEndpoints = [
      `${apiUrl}/test/clearCache`,
      `${apiUrl}/report/clearCache`,
      `${apiUrl}/clientProfile/clearCache`
    ];

    try {
      const responses = await Promise.all(cacheEndpoints.map(endpoint => 
        fetch(endpoint, { method: 'POST', headers })
      ));
      
      responses.forEach(response => {
        if (!response.ok) {
          throw new Error(`Failed to clear cache for ${response.url}: ${response.statusText}`);
        }
      });

      console.log('All caches cleared successfully');
    } catch (error) {
      console.error('Error clearing cache:', error.message, error.stack);
    }
  };

  const fetchData = async (url, setter, errorMessage, headers) => {
    try {
      const response = await fetch(url, { headers });
      if (response.status === 401) {
        // Queue the token refresh request if not already refreshing
        if (!isRefreshingToken) {
          isRefreshingToken = true;
          await refreshToken();
        } else {
          // If token refresh is in progress, queue the current fetch request
          tokenRefreshQueue.push(() => fetchData(url, setter, errorMessage, headers));
        }
      } else {
        const data = await response.json();
        if (response.ok) {
          setter(data);
          console.log(`Successfully fetched data from ${url}`);
        } else {
          console.error(`${errorMessage}: ${response.status} ${response.statusText}`);
        }
      }
    } catch (error) {
      console.error(`${errorMessage}:`, error);
    }
  };

  useEffect(() => {
    if (userId && !clientProfile) {
      const storedToken = getData('accessToken');
      fetchData(
        `${apiUrl}/clientProfile/${userId}`,
        (profileData) => {
          setClientProfile(profileData);
          setData('apiKey', profileData.apiToken); // Store API key in local storage
        },
        'Error fetching client profile',
        { Authorization: `Bearer ${storedToken}` }
      );
    }
  }, [userId, clientProfile, apiUrl, getData, setData]);

  useEffect(() => {
    if (clientProfile && clientProfile.apiToken) {
      const apiTokenHeader = { 'x-api-key': clientProfile.apiToken };
      fetchData(`${apiUrl}/test/fetch/all`, setTests, 'Error fetching tests', apiTokenHeader);
      fetchData(`${apiUrl}/report/fetch/all`, setReports, 'Error fetching reports', apiTokenHeader);
      fetchData(`${apiUrl}/insight/fetch/all`, setInsights, 'Error fetching insights', apiTokenHeader);
    }
  }, [clientProfile, apiUrl]);

  return (
    <clientDataContext.Provider value={{ clientProfile, setClientProfile, tests, reports, insights }}>
      {children}
    </clientDataContext.Provider>
  );
};