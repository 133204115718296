import React, { useState, useEffect } from 'react';
import './Home.css';
import ScrollIndicator from './ScrollIndicator';

const sections = [
  { id: 'what-section', label: 'What We Do' },
  { id: 'why-section', label: 'Why Us' },
  { id: 'how-section', label: 'How We Work' }
];

function Home() {
  const [isCoverVisible, setCoverVisible] = useState(true);
  const [isSecondCoverVisible, setSecondCoverVisible] = useState(true);

  const handleButtonClick = () => {
    setCoverVisible(false);
  };

  const handleScroll = () => {
    const middleOfViewport = window.scrollY + window.innerHeight / 2;
    const secondSection = document.getElementById('second-interactive-image');
    
    if (secondSection) {
      const sectionTop = secondSection.offsetTop;
      const sectionHeight = secondSection.offsetHeight;
      const sectionScrollThreshold = sectionTop + sectionHeight * 1.3; // Adjust 0.6 as needed
  
      if (middleOfViewport >= sectionScrollThreshold) {
        setSecondCoverVisible(false);
      } else {
        setSecondCoverVisible(true);
      }
    }
  };
  
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <ScrollIndicator sections={sections} />
      <div className="home">
        <div className="section welcome-section" id="welcome-section">
          <img src="/images/st-logo-full.png" alt="SourceTrooper Full Logo" className="full-logo" />
          <h1>We Test so you don't have to (period).</h1>
          <p>Your reliable partner for post-deployment testing.</p>
        </div>
        <div className="section what-section" id="what-section">
          <h2>What We Do</h2>
          <p>We provide <strong>peace of mind</strong> by offering <strong>testing services</strong> to ensure your mobile and web applications perform flawlessly, scale beautifully, and deliver the seamless user experience your customers deserve.
          </p>

          <div className="interactive-image">
            <div className="image-container">
              <img src="/images/bug-background.jpg" alt="Bug Background" className="background-image" />
              <img
                src="/images/bug-background-cover.jpg"
                alt="Bug Background Cover"
                className={`cover-image ${!isCoverVisible ? 'hidden' : ''}`}
              />
              <button className="floating-button" onClick={handleButtonClick}>Run all tests</button>
            </div>
            <div className="text-container">
              <h3>Reduce All Testing to a Simple Button</h3>
              <p>Let us help you automate your testing to a simple click - "Run all tests". With the click of a button, we will highlight all the issues and bugs in your product, so you can focus on development, leave the Testing to us.</p>
            </div>
          </div>

          <div className="interactive-image" id="second-interactive-image">
            <div className="image-container">
              <img src="/images/useful-report-background.jpg" alt="Useful Report Background" className="background-image" />
              <img
                src="/images/useful-report-background-cover.jpg"
                alt="Useful Report Background Cover"
                className={`cover-image ${!isSecondCoverVisible ? 'hidden' : ''}`}
              />
            </div>
            <div className="text-container">
              <h3>Simple, On-Point Insights</h3>
              <p>Remove all the complexity in reading bug and error reports. With us, you get simple, direct-to-the-point insights. Companies usually lose valuable time reading reports. We can simplify this for you, making sure you save time and focus on what's important.</p>
            </div>
          </div>
        </div>
        <div className="section why-section" id="why-section">
          <h2>Why Choose SourceTrooper</h2>
          <p>            Our meticulous testing covers every aspect of your application, from <strong>performance</strong> to <strong>availability</strong>, so you can focus on innovation while we guarantee perfection.</p>
          <div className="interactive-image">
            <div className="image-container">
              <img src="/images/working-background.jpg" alt="Working Background" className="floating-image" />
            </div>
            <div className="text-container">
              <h3>Leading the Way in Testing Innovation</h3>
              <p>We are up to date with the latest testing protocols, automation, and continuously improve our testing procedures so your product always remains functional and works as intended.</p>
              <br/>
              <h3>Continual Improvement</h3>
              <p>Companies spend significant time and money creating and maintaining testing processes. Let us handle that for you, ensuring your product always performs at its best while you focus on innovation.</p>
            </div>
          </div>
        </div>
        <div className="section how-section" id="how-section">
          <h2>How We Work</h2>
          <div className="how-content">
            <p>We provide comprehensive testing solutions for Mobile and Web Applications.</p>
            <div className="how-diagram">
              <img src="images/testing-process.png" alt="Testing Process Diagram" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;