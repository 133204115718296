import React, { useState, useEffect } from 'react';
import './Setup.css';
import { StorageUtil } from '../../utils/storageUtil';
import { useClientData } from '../../contexts/clientDataContext';
import { useDispatch } from 'react-redux';
import { logout } from '../../actions/authActions';

const Setup = () => {
  const { clientProfile, setClientProfile } = useClientData();
  const [localClientProfile, setLocalClientProfile] = useState({
    website: '',
    apiToken: '',
    androidAppPath: '',
    androidAppUploaded: '',
    iosAppPath: '',
    iosAppUploaded: ''
  });
  const { getData, setData, removeData } = StorageUtil();
  const dispatch = useDispatch();
  const userData = getData('userData');
  const userId = userData ? userData.id : null;
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (clientProfile) {
      setLocalClientProfile(clientProfile);
    } else {
      fetchClientProfile();
    }
  }, [clientProfile]);

  const refreshToken = async () => {
    try {
      const storedRefreshToken = getData('refreshToken');
      if (!storedRefreshToken) {
        handleTokenRefreshError('No refresh token available');
        return;
      }

      const response = await fetch(`${apiUrl}/auth/refresh-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: storedRefreshToken }),
      });

      if (response.ok) {
        const { accessToken, refreshToken } = await response.json();
        setData('accessToken', accessToken);
        setData('refreshToken', refreshToken);
        setData('apiKey', accessToken); // Update API key in storage
        console.log('Token refreshed successfully');
        return accessToken;
      } else {
        handleTokenRefreshError('Failed to refresh token');
      }
    } catch (error) {
      handleTokenRefreshError('Error refreshing token:', error);
    }
  };

  const handleTokenRefreshError = async (message, error) => {
    console.error(`ClientDataProvider: ${message}`, error);
    await removeBackendCaches();

    removeData('isAuthenticated', 'localStorage');
    removeData('accessToken');
    removeData('refreshToken');
    removeData('apiKey');
    removeData('userData');
    dispatch(logout());
    window.location.href = '/login';
  };

  const removeBackendCaches = async () => {
    let token = getData('accessToken');
    const headers = {
      'Authorization': `Bearer ${token}`
    };

    const cacheEndpoints = [
      `${apiUrl}/test/clearCache`,
      `${apiUrl}/report/clearCache`,
      `${apiUrl}/clientProfile/clearCache`
    ];

    try {
      const responses = await Promise.all(cacheEndpoints.map(endpoint => 
        fetch(endpoint, { method: 'POST', headers })
      ));
      
      responses.forEach(response => {
        if (!response.ok) {
          throw new Error(`Failed to clear cache for ${response.url}: ${response.statusText}`);
        }
      });

      console.log('All caches cleared successfully');
    } catch (error) {
      console.error('Error clearing cache:', error.message, error.stack);
    }
  };

  const fetchClientProfile = async () => {
    try {
      let storedToken = getData('accessToken');
      if (!storedToken) {
        console.error('Setup: No token found');
        return;
      }

      let response = await fetch(`${apiUrl}/clientProfile/${userId}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });

      if (response.status === 401) {
        storedToken = await refreshToken();
        if (!storedToken) return;

        response = await fetch(`${apiUrl}/clientProfile/${userId}`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });
      }

      if (response.ok) {
        const data = await response.json();
        setLocalClientProfile(data);
        setClientProfile(data); // Update clientProfile in context
        setData('apiKey', data.apiToken); // Store API key in local storage
        console.log('Setup: Successfully fetched client profile');
      } else {
        const data = await response.json();
        console.error('Setup: Error fetching client profile:', data.error);
      }
    } catch (error) {
      console.error('Setup: Error fetching client profile:', error);
    }
  };

  const handleCopyToken = () => {
    navigator.clipboard.writeText(localClientProfile.apiToken).then(() => {
      alert('Token copied to clipboard');
    }).catch((err) => {
      console.error('Error copying token:', err);
    });
  };

  const handleGenerateNewAPIKey = async () => {
    try {
      let storedToken = getData('accessToken');
      if (!storedToken) {
        console.error('Setup: No token found');
        return;
      }

      let response = await fetch(`${apiUrl}/clientProfile/generateAPIKey/${userId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${storedToken}`,
        },
      });

      if (response.status === 401) {
        storedToken = await refreshToken();
        if (!storedToken) return;

        response = await fetch(`${apiUrl}/clientProfile/generateAPIKey/${userId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${storedToken}`,
          },
        });
      }

      if (response.ok) {
        const data = await response.json();
        setData('apiKey', data.apiKey);
        const updatedProfile = { ...localClientProfile, apiToken: data.apiKey };
        setLocalClientProfile(updatedProfile);
        setClientProfile(currentProfile => ({ ...currentProfile, ...updatedProfile }));        
        console.log('Setup: Successfully generated new API Key');
      } else {
        const data = await response.json();
        console.error('Setup: Error generating new API Key:', data.error);
      }
    } catch (error) {
      console.error('Setup: Error generating new API Key:', error);
    }
  };

  const handleUploadFile = async (event, fileType) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    const url = `${apiUrl}/upload/${fileType}/${userId}`;
    
    try {
      let storedToken = getData('accessToken');
      if (!storedToken) {
        console.error('No token found');
        return;
      }

      let response = await fetch(url, {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Bearer ${storedToken}`,
        },
      });

      if (response.status === 401) {
        storedToken = await refreshToken();
        if (!storedToken) return;

        response = await fetch(url, {
          method: 'POST',
          body: formData,
          headers: {
            'Authorization': `Bearer ${storedToken}`,
          },
        });
      }

      if (response.ok) {
        const data = await response.json();
        alert(data.message);
        if (fileType === 'android') {
          setLocalClientProfile({ ...localClientProfile, androidAppPath: data.filePath, androidAppUploaded: data.uploadTime });
        } else if (fileType === 'ios') {
          setLocalClientProfile({ ...localClientProfile, iosAppPath: data.filePath, iosAppUploaded: data.uploadTime });
        }
        console.log(`Setup: Successfully uploaded ${fileType} file`);
      } else {
        const data = await response.json();
        console.error('Error response:', data);
        alert('Error uploading file: ' + data.message);
      }
    } catch (error) {
      console.error(`Error uploading ${fileType} file:`, error);
    }
  };

  const handleResetFile = (fileType) => {
    if (fileType === 'android') {
      setLocalClientProfile({
        ...localClientProfile,
        androidAppPath: '',
        androidAppUploaded: '',
      });
    } else if (fileType === 'ios') {
      setLocalClientProfile({
        ...localClientProfile,
        iosAppPath: '',
        iosAppUploaded: '',
      });
    }
  };

  return (
    <div className="full-page-container">
      <div className="setup-page-container">
        <div className="setup-header">
          <h1>Setup</h1>
        </div>
        <div className="setup-content">
          <h2 className="section-title">Web Testing</h2>
          <div className="field">
            <label>Website URL</label>
            <input type="text" value={localClientProfile.website} readOnly className="readonly-input" />
          </div>
          <div className="field">
            <label>API Key</label>
            <input type="text" value={localClientProfile.apiToken} readOnly className="readonly-input" />
            <div className="button-group">
              <button onClick={handleCopyToken}>Copy Token</button>
              <button onClick={handleGenerateNewAPIKey}>Generate New Key</button>
            </div>
          </div>
          <div className="separator"></div>
          <h2 className="section-title">Mobile Testing</h2>
          <div className="upload-fields">
            <div>
              <label>Android APK</label>
              {localClientProfile.androidAppPath ? (
                <div className="uploaded-info">
                  <span>File Uploaded: {localClientProfile.androidAppPath}</span>
                  <br />
                  <span>Uploaded Time: {new Date(localClientProfile.androidAppUploaded).toLocaleString('en-GB')}</span>
                  <br />
                  <button onClick={() => handleResetFile('android')}>Re-upload</button>
                </div>
              ) : (
                <input type="file" onChange={(e) => handleUploadFile(e, 'android')} />
              )}
            </div>
            <div>
              <label>iOS App</label>
              {localClientProfile.iosAppPath ? (
                <div className="uploaded-info">
                  <span>File Uploaded: {localClientProfile.iosAppPath}</span>
                  <br />
                  <span>Uploaded Time: {localClientProfile.iosAppUploaded ? new Date(localClientProfile.iosAppUploaded).toLocaleString('en-GB') : 'N/A'}</span>
                  <br />
                  <button onClick={() => handleResetFile('ios')}>Re-upload</button>
                </div>
              ) : (
                <input type="file" onChange={(e) => handleUploadFile(e, 'ios')} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Setup;