// src/components/NotFound.js
import React from 'react';
import './NotFound.css'; // Import the CSS file for styling

const NotFound = () => {
  return (
    <div className="not-found">
      <div className="not-found-overlay">
        <h1 className="not-found-title">404</h1>
        <p className="not-found-message">Sorry, the page you are looking for does not exist.</p>
      </div>
    </div>
  );
};

export default NotFound;
