import React, { useState, useEffect } from 'react';
import './Tests.css';
import { useClientData } from '../../contexts/clientDataContext';

const Tests = () => {
  const { tests, clientProfile } = useClientData();
  const [localTests, setLocalTests] = useState(tests);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (clientProfile?.apiToken) {
      fetchTests();
    }
  }, [clientProfile, apiUrl]);

  const fetchTests = async () => {
    try {
      const response = await fetch(`${apiUrl}/test/fetch/all`, {
        headers: { 'x-api-key': clientProfile.apiToken }
      });
      const data = await response.json();
      setLocalTests(data);
    } catch (error) {
      console.error('Error fetching tests:', error);
    }
  };

  const runAllTests = async () => {
    try {
      const response = await fetch(`${apiUrl}/test/run/all`, {
        headers: { 'x-api-key': clientProfile.apiToken }
      });

      if (!response.ok) {
        // Try to extract error message from the response
        let errorMessage = ``;
        try {
          const errorText = await response.text();
          errorMessage += ` Server responded with: ${errorText}`;
        } catch (textError) {
          errorMessage += ' Failed to read error message from response.';
        }
        throw new Error(errorMessage);
      }

      const result = await response.json();
      alert(`Run All Tests Result: ${JSON.stringify(result)}`);
    } catch (error) {
      console.error('Error running all tests:', error);
      alert(`Error running all tests: ${error.message}`);
    }
  };

  const runSingleTest = async (testName) => {
    try {
      const response = await fetch(`${apiUrl}/test/run/${testName}`, {
        headers: { 'x-api-key': clientProfile.apiToken }
      });

      if (!response.ok) {
        // Try to extract error message from the response
        let errorMessage = ``;
        try {
          const errorText = await response.text();
          errorMessage += ` Server responded with: ${errorText}`;
        } catch (textError) {
          errorMessage += ' Failed to read error message from response.';
        }
        throw new Error(errorMessage);
      }

      const result = await response.json();
      alert(`Run ${testName} Test Result: ${JSON.stringify(result)}`);
    } catch (error) {
      console.error(`Error running ${testName} test:`, error);
      alert(`Error running ${testName} test: ${error.message}`);
    }
  };

  const handleToggle = (parentTest) => {
    const element = document.getElementById(parentTest);
    const countElement = document.getElementById(`count-${parentTest}`);
    if (element && countElement) {
      element.classList.toggle('collapsed');
      countElement.classList.toggle('hidden');
    }
  };

  return (
    <div className="full-page-container">
      <div className="tests-page-container">
        <div className="tests-header">
          <h1>Tests</h1>
          {Object.keys(localTests).some(parentTest => Array.isArray(localTests[parentTest]) && localTests[parentTest].length > 0) ? (
            <button className="run-all-button" onClick={runAllTests}>Run All Tests</button>
          ) : null}
        </div>
        <div className="tests-list">
          {Object.keys(localTests).length > 0 ? (
            Object.keys(localTests).map((parentTest) => (
              <div key={parentTest} className="parent-test">
                <div className="parent-test-header" onClick={() => handleToggle(parentTest)}>
                  <h2>{parentTest}</h2>
                  <span id={`count-${parentTest}`} className="test-count">
                    {localTests[parentTest].length}
                  </span>
                </div>
                <div id={parentTest} className="parent-test-details">
                  {Array.isArray(localTests[parentTest]) && localTests[parentTest].length > 0 ? (
                    localTests[parentTest].map((childTest) => (
                      <div key={childTest} className="child-test">
                        <span>{childTest}</span>
                        <button onClick={() => runSingleTest(childTest)}>Run Test</button>
                      </div>
                    ))
                  ) : (
                    <div className="no-children">No tests available</div>
                  )}
                </div>
              </div>
            ))
          ) : (
            <div>No tests available</div>
          )}
        </div>
      </div>
    </div>
  );  
};

export default Tests;