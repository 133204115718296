import React from 'react';
import './Policy.css';

const Disclaimer = () => {
  return (
    <div className="policy-container">
      <h1>Disclaimer</h1>
      <p>The information provided by SourceTrooper on our website and through our services is for general informational purposes only. All information on our website is provided in good faith; however, we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the site.</p>
      
      <h2>Limitation of Liability</h2>
      <p>Under no circumstance shall we have any liability to you for any loss or damage of any kind incurred as a result of the use of the site or reliance on any information provided on the site. Your use of the site and your reliance on any information on the site is solely at your own risk.</p>
      
      <h2>External Links</h2>
      <p>The site may contain (or you may be sent through the site) links to other websites or content belonging to or originating from third parties or links to websites and features in banners or other advertising. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability, or completeness by us.</p>
      
      <h2>Professional Advice</h2>
      <p>The site cannot and does not contain legal advice. The legal information is provided for general informational and educational purposes only and is not a substitute for professional advice. Accordingly, before taking any actions based upon such information, we encourage you to consult with the appropriate professionals. We do not provide any kind of legal advice.</p>
      
      <h2>Contact Us</h2>
      <p>If you have any questions or concerns about this Disclaimer, please contact us using the information provided in the Contact Us section of our website.</p>
    </div>
  );
};

export default Disclaimer;
