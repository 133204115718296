import React from 'react';
import './Policy.css';

const CookiePolicy = () => {
  return (
    <div className="policy-container">
      <h1>Cookie Policy</h1>
      <p>At SourceTrooper, we are committed to protecting your privacy. This Cookie Policy explains how we use cookies on our website. By using our website, you consent to the use of cookies as described in this policy.</p>
      
      <h2>What are Cookies?</h2>
      <p>Cookies are small text files that are stored on your device (computer, tablet, or mobile phone) when you visit a website. They are widely used to make websites work more efficiently and to provide information to the website owners.</p>
      
      <h2>Types of Cookies We Use</h2>
      <p>We use cookies for the following purposes:</p>
      <ul>
        <li><strong>Authentication:</strong> We use cookies to authenticate users and ensure secure access to our services. These cookies store essential information such as login credentials, tokens, and API keys while you are logged in.</li>
        <li><strong>Preferences:</strong> We may use cookies to remember your preferences and settings, such as language preferences, to enhance your user experience.</li>
      </ul>
      
      <h2>Third-Party Cookies</h2>
      <p>We do not use any third-party cookies for analytics, advertising, or promotional purposes.</p>
      
      <h2>Managing Cookies</h2>
      <p>You can control and manage cookies in various ways. Please note that removing or blocking cookies may impact your user experience and functionality of our website.</p>
      <ul>
        <li><strong>Browser Settings:</strong> You can adjust your browser settings to accept or refuse cookies. Refer to your browser's help menu for instructions on how to manage cookies.</li>
        <li><strong>Opting Out:</strong> If you do not wish to accept cookies from our website, you can choose to disable cookies through your browser settings or refrain from using our services.</li>
      </ul>
      
      <h2>Your Consent</h2>
      <p>By using our website and accepting cookies when prompted by the GDPR consent popup, you consent to the use of cookies as described in this policy.</p>
      
      <h2>Contact Us</h2>
      <p>If you have any questions or concerns about our Cookie Policy or how we use cookies on our website, please contact us using the information provided in the Contact Us section of our website.</p>
    </div>
  );
};

export default CookiePolicy;
