import React from 'react';
import './Policy.css';

const TermsOfService = () => {
  return (
    <div className="policy-container">
      <h1>Terms of Service</h1>
      <p>Welcome to SourceTrooper. These Terms of Service govern your use of our website and services. By accessing or using our services, you agree to these terms. If you do not agree with any part of these terms, you may not use our services.</p>
      
      <h2>Services</h2>
      <p><strong>Scope of Services:</strong> SourceTrooper provides post-deployment testing services for mobile and web applications. Our services include functionality testing, performance testing, security testing, and more as detailed on our website.</p>
      
      <h2>Client Responsibilities</h2>
      <p><strong>Accurate Information:</strong> You agree to provide accurate information necessary for testing your applications. You are responsible for the legality and compliance of your applications with applicable laws and regulations.</p>
      <p><strong>Data Provided:</strong> You must only provide data that is intended for public release. SourceTrooper is not responsible for handling any personal information, customer data, or business secrets not meant for public deployment.</p>
      
      <h2>Payment</h2>
      <p><strong>Pricing:</strong> You agree to pay for services as outlined in our pricing plans. Payment terms are specified in your agreement or invoice.</p>
      
      <h2>Confidentiality</h2>
      <p><strong>Confidentiality:</strong> We maintain the confidentiality of your application data provided for testing purposes and do not share it with third parties without your consent, except as required by law.</p>
      
      <h2>Limitation of Liability</h2>
      <p><strong>Testing Accuracy:</strong> While we strive for accurate testing, we do not guarantee the detection of all bugs or issues. Our liability is limited to the amount you paid us in the twelve months preceding the claim.</p>
      
      <h2>Termination</h2>
      <p><strong>Termination:</strong> You may terminate our services at any time. We reserve the right to terminate or suspend services for non-payment or breach of these terms.</p>
      
      <h2>Governing Law</h2>
      <p><strong>Jurisdiction:</strong> These Terms of Service are governed by and construed in accordance with the laws of the jurisdiction in which SourceTrooper operates. Any disputes arising from these terms shall be resolved in the courts of that jurisdiction.</p>
      
      <h2>Amendments</h2>
      <p><strong>Changes to Terms:</strong> SourceTrooper reserves the right to amend these Terms of Service at any time. Any changes will be effective immediately upon posting on our website. Your continued use of our services constitutes your acceptance of the amended terms.</p>
      
      <h2>Contact Information</h2>
      <p><strong>Inquiries:</strong> For any questions or concerns regarding these Terms of Service, please contact us using the information provided in the Contact Us section of our website.</p>
      
      <p>By using SourceTrooper’s services, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service.</p>
    </div>
  );
};

export default TermsOfService;
