import React, { useState, useEffect } from 'react';
import './ContactUs.css';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import ScrollIndicator from './ScrollIndicator';

const sections = [
  { id: 'contact-form-section', label: 'Contact Form' },
  { id: 'faq-section', label: 'FAQs' }
];

function ContactUs() {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [captcha, setCaptcha] = useState('');
  const [captchaInput, setCaptchaInput] = useState('');
  const [faqOpen, setFaqOpen] = useState({});
  
  const faqData = [
    {
      question: 'What services do you offer?',
      answer: 'We offer a full spectrum of web and mobile testing services, including functionality and usability testing, performance testing, accessibility testing, security testing, load testing, API testing, automated regression testing, compatibility testing, and automated UI testing. Our services are designed to ensure your application\'s quality and performance on all platforms.',
    },
    {
      question: 'How does your pricing work?',
      answer: 'Our pricing is designed to scale with your needs, ensuring you get the most value. We charge $200 per feature/month, every feature test will include 3 testing scenarios of your choice. Additionally, you can run individual or all tests whenever you want, how you want - via Portal or API.',
    },
    {
      question: 'Can you give an example of a feature to test?',
      answer: 'Of course! Lets take the load test as an example. Here one testing scenario can be whether your site can handle sudden increase of users. Another example, Cart Checkout feature, a scenario here could be can the customer checkout without any issues',
    },
    {
      question: 'What happens if you cannot create automated tests for particular features?',
      answer: 'In cases where a feature cannot be automated, we offer manual testing as part of our service. We will manually test the feature on different devices to ensure comprehensive coverage and reliability. Our goal is to support you in every way possible to ensure your success.',
    },
    {
      question: 'How do you handle testing features that require payment?',
      answer: 'For features that involve payments, we typically use dummy credit cards provided by your payment gateway. These cards allow us to conduct transactions in a sandbox environment to ensure everything functions correctly without affecting real accounts. We work closely with your team to acquire the necessary testing credentials and ensure that our tests mirror the real-world scenarios your users will encounter.',
    },
    {
      question: 'What if my developers change soemthing, and the tests fail, who will update this?',
      answer: 'Dont worry, Think of us as part of your team, we will update the tests once you inform us and give us a heads up. This wont delay your release as we will give you our feedback immediately once a manual test is completed.',
    },
    {
      question: 'Why choose SourceTrooper over hiring individual QA testers?',
      answer: 'Choosing SourceTrooper means opting for a cost-effective, scalable solution without the overhead of in-house QA. Unlike the fixed costs associated with full-time employees, our services are flexible and you only pay for what you need. With no significant investment required and the ability to get started within 48 hours, we offer expertise and round-the-clock availability on a month-to-month commitment.',
    },
    {
      question: 'How do you ensure the security of our data during testing?',
      answer: 'Security is paramount at SourceTrooper. We test only what you plan to deploy to your end users, meaning there are no additional data requirements or security concerns. By focusing on the final product, we minimize the risk of breaches and ensure the integrity of your data throughout the testing process.',
    },
    {
      question: 'What sets your testing process apart from others?',
      answer: 'Our testing process is built on a foundation of industry best practices, incorporating both automated and manual testing methodologies to ensure comprehensive coverage. We utilize state-of-the-art tools and techniques to simulate real-world usage scenarios, providing you with actionable insights and a robust product ready for market.',
    },
    {
      question: 'Can you handle large-scale testing projects?',
      answer: 'Yes, our infrastructure and team are equipped to manage large-scale testing projects with efficiency. We scale our resources to meet your project\'s demands, ensuring timely delivery without compromising on quality.',
    },
    {
      question: 'What kind of support can we expect after the testing is complete?',
      answer: 'Post-testing, we provide detailed reports, recommendations for improvements, and, if needed, further testing cycles. Our team is available for any follow-up questions or additional support to ensure your application performs flawlessly.',
    },
    {
      question: 'Do you offer any guarantees with your testing services?',
      answer: 'We stand behind the quality of our testing services with a satisfaction guarantee. If any issues arise post-deployment that were within our testing scope, we will retest at no additional cost to ensure your product\'s integrity.',
    },
  ];

  const generateCaptcha = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let captcha = '';
    for (let i = 0; i < 6; i++) {
      captcha += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    setCaptcha(captcha);
  };

  const handleFaqToggle = (index) => {
    setFaqOpen((prevFaqOpen) => ({
      ...prevFaqOpen,
      [index]: !prevFaqOpen[index],
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (captcha !== captchaInput) {
      alert('CAPTCHA is incorrect. Please try again.');
      return;
    }
    window.location.href = `mailto:sourcetrooper@gmail.com?subject=Contact Us&body=Name: ${name}%0D%0AContact Number: ${phone}%0D%0AMessage: ${message}`;
  };

  useEffect(() => {
    generateCaptcha();
  }, []);

  return (
    <>
      <ScrollIndicator sections={sections} />
      <div className="contact-us">
        <div className="section contact-section" id="contact-section">
          <h1>Contact Us</h1>
          <p>Get in touch with us for any queries or to get started with our services. We're here to help!</p>
        </div>
        <div className="section contact-form-section" id="contact-form-section">
          <h2>Contact Form</h2>
          <form onSubmit={handleSubmit} className="contact-form">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <label htmlFor="phone">Contact Number:</label>
            <PhoneInput
              country={'us'}
              value={phone}
              onChange={(phone) => setPhone(phone)}
              inputProps={{
                name: 'phone',
                required: true,
                autoFocus: false,
              }}
              containerStyle={{ textAlign: 'left' }}
              inputStyle={{ width: '100%', textAlign: 'left', color: '#000' }}
              buttonStyle={{ color: '#000' }}
            />
            <label htmlFor="message">Message:</label>
            <textarea
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
            <div className="captcha-container">
              <label htmlFor="captcha">CAPTCHA:</label>
              <div className="captcha-code">{captcha}</div>
              <input
                type="text"
                id="captcha"
                value={captchaInput}
                onChange={(e) => setCaptchaInput(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="contact-button">Contact Us</button>
          </form>
        </div>
        <div className="section faq-section" id="faq-section">
          <h2>FAQs</h2>
          {faqData.map((faq, index) => (
            <div className="faq-item" key={index}>
              <button onClick={() => handleFaqToggle(index)} className="faq-question">
                {faq.question}
              </button>
              {faqOpen[index] && (
                <div className="faq-answer">
                  <p>{faq.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default ContactUs;