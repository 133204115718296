import React/*, { useEffect }*/ from 'react';
import { Link, Outlet, useLocation/*, useNavigate*/ } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../actions/authActions';
import './Dashboard.css';
import { StorageUtil } from '../utils/storageUtil';

const Dashboard = () => {
  const dispatch = useDispatch();
  const { getData, removeData } = StorageUtil();
  const userData = getData('userData');
  const token = getData('accessToken');
  const username = userData ? userData.username : '';
  const location = useLocation();
  //const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  /*
  useEffect(() => {
    if (location.pathname === '/dashboard') {
      navigate('/dashboard/insights');
    }
  }, [location, navigate]);
  */

  const removeBackendCaches = async () => {
    const headers = {
      'Authorization': `Bearer ${token}`
    };

    const cacheEndpoints = [
      `${apiUrl}/test/clearCache`,
      `${apiUrl}/report/clearCache`,
      `${apiUrl}/clientProfile/clearCache`
    ];

    try {
      const responses = await Promise.all(cacheEndpoints.map(endpoint => 
        fetch(endpoint, { method: 'POST', headers })
      ));
      
      responses.forEach(response => {
        if (!response.ok) {
          throw new Error(`Failed to clear cache for ${response.url}: ${response.statusText}`);
        }
      });

      console.log('All caches cleared successfully');
    } catch (error) {
      console.error('Error clearing cache:', error.message, error.stack);
    }
  };

  const handleLogout = async () => {
    await removeBackendCaches();

    removeData('isAuthenticated', 'localStorage');
    removeData('accessToken');
    removeData('refreshToken');
    removeData('apiKey');
    removeData('userData');
    dispatch(logout());
    window.location.href = '/login';
  };

  return (
    <div className="dashboard-wrapper">
      <div className="dashboard-container">
        <div className="side-menu">
          <ul>
            <li><Link to="/dashboard/insights">Insights</Link></li>
            <li><Link to="/dashboard/setup">Setup</Link></li>
            <li><Link to="/dashboard/tests">Tests</Link></li>
            <li><Link to="/dashboard/reports">Reports</Link></li>
            <li><Link to="/dashboard/requests">Requests</Link></li>
            <li>
              <button onClick={handleLogout} className="logout-button">Logout</button>
            </li>
          </ul>
        </div>
        <div className="main-content">
          {location.pathname === "/dashboard" ? (
            <div className="welcome-message">
              <img src="/images/st-logo-full.png" alt="SourceTrooper Full Logo" className="dashboard-full-logo" />
              <h2>Welcome to your Dashboard, {username}</h2>
            </div>
          ) : (
            <Outlet />
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;