import React from 'react';
import Slider from 'react-slick';
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer, Legend } from 'recharts';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './LoadCard.css';

const LoadCard = ({ title, data = {} }) => {
  const {
    averageResponseTime = 0,
    maxResponseTime = 0,
    minResponseTime = 0,
    ninetyFifthPercentileResponseTime = 0,
    failedRequestsCount = 0,
    totalRequests = 0,
    successfulRequests = 0,
    dataSentBytes = 0,
    dataReceivedBytes = 0,
    failedRequestsRate = 0,
    customMetricThresholdMet = false
  } = data;

  const requestsData = [
    { name: 'Total', value: totalRequests },
    { name: 'Success', value: successfulRequests },
    { name: 'Failed', value: failedRequestsCount }
  ];

  const responseTimeData = [
    { name: 'Avg', value: averageResponseTime },
    { name: 'Max', value: maxResponseTime },
    { name: 'Min', value: minResponseTime },
    { name: '95th Percentile', value: ninetyFifthPercentileResponseTime }
  ];

  const dataTransferData = [
    { name: 'Sent', value: dataSentBytes },
    { name: 'Received', value: dataReceivedBytes }
  ];

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      /*{
        breakpoint: 768,
        settings: {
          dots: true,
          arrows: true,
          infinite: true,
          slidesToScroll: 1,
          swipeToSlide: true,
        }
      },*/
      {
        breakpoint: 992,
        settings: 'unslick',
      }
    ]
  };

  return (
    <div className="card">
      <h3>{title}</h3>
      <Slider {...settings}>
        <div>
          <h4>Requests Overview</h4>
          <ResponsiveContainer width="100%" height={200}>
            <BarChart data={requestsData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="value" fill="#36A2EB" />
            </BarChart>
          </ResponsiveContainer>
          <div className="summary">
            <div className="summary-item">
              <strong>Total Requests:</strong> {totalRequests}
            </div>
            <div className="summary-item">
              <strong>Success Rate:</strong> {(successfulRequests / totalRequests * 100).toFixed(2)}%
            </div>
            <div className="summary-item">
              <strong>Failed Requests Rate:</strong> {failedRequestsRate.toFixed(2)}%
            </div>
            <div className="summary-item">
              <strong>Custom Metric Threshold Met:</strong> {customMetricThresholdMet ? 'Yes' : 'No'}
            </div>
          </div>
        </div>
        <div>
          <h4>Response Times</h4>
          <ResponsiveContainer width="100%" height={200}>
            <LineChart data={responseTimeData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis unit="ms" />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="value" stroke="#FF6384" />
            </LineChart>
          </ResponsiveContainer>
          <div className="summary">
            <div className="summary-item">
              <strong>Average Response Time:</strong> {averageResponseTime} ms
            </div>
            <div className="summary-item">
              <strong>Max Response Time:</strong> {maxResponseTime} ms
            </div>
            <div className="summary-item">
              <strong>Min Response Time:</strong> {minResponseTime} ms
            </div>
            <div className="summary-item">
              <strong>95th Percentile:</strong> {ninetyFifthPercentileResponseTime} ms
            </div>
          </div>
        </div>
        <div>
          <h4>Data Transfer</h4>
          <ResponsiveContainer width="100%" height={200}>
            <BarChart data={dataTransferData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="value" fill="#FFCE56" />
            </BarChart>
          </ResponsiveContainer>
          <div className="summary">
            <div className="summary-item">
              <strong>Data Sent:</strong> {dataSentBytes} bytes
            </div>
            <div className="summary-item">
              <strong>Data Received:</strong> {dataReceivedBytes} bytes
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default LoadCard;
