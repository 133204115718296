import React, { useState } from 'react';
import './CookieConsentPopup.css';
import { StorageUtil } from '../../utils/storageUtil';

const CookieConsentPopup = () => {
  const { setUserConsent } = StorageUtil();
  const [dismissed, setDismissed] = useState(false);

  const handleAccept = () => {
    setUserConsent(true); // Save user's consent preference
    setDismissed(true); // Dismiss the popup
  };

  const handleReject = () => {
    setUserConsent(false); // Save user's consent preference
    setDismissed(true); // Dismiss the popup
  };

  // Don't render the popup if it has been dismissed
  if (dismissed) {
    return null;
  }

  return (
    <div className="cookie-consent-popup">
      <div className="content">
        <h2>Cookie Policy</h2>
        <p>This website uses cookies to ensure you get the best experience on our website.</p>
        <div className="buttons">
          <button onClick={handleAccept}>Accept</button>
          <button onClick={handleReject}>Reject</button>
        </div>
      </div>
    </div>
  );
};

export default CookieConsentPopup;
