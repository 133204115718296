import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';

const AuthGuard = ({ children }) => {
  const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
  const location = useLocation();
  const pathname = location.pathname.toLowerCase();

  // If authenticated and trying to access login, redirect to dashboard
  if (isAuthenticated && pathname === '/login') {
    return <Navigate to="/dashboard" replace />;
  }

  // If not authenticated and trying to access dashboard, redirect to login
  if (!isAuthenticated && pathname.startsWith('/dashboard')) {
    return <Navigate to="/login" replace />;
  }

  // Allow access to the protected routes
  return children;
};

export default AuthGuard;
