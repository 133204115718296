import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'; 
import { login } from '../actions/authActions'; 
import { Link } from 'react-router-dom';
import './Login.css';
import {StorageUtil} from '../utils/storageUtil'; // Import StorageUtil

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [captcha, setCaptcha] = useState('');
  const [captchaInput, setCaptchaInput] = useState('');
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [attemptCount, setAttemptCount] = useState(0);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const dispatch = useDispatch(); 
  const apiUrl = process.env.REACT_APP_API_URL;
  const { setData } = StorageUtil();

  useEffect(() => {
    if (attemptCount >= 3 && !isForgotPassword) {
      setShowCaptcha(true);
      generateCaptcha();
    }
  }, [attemptCount, isForgotPassword]);

  const generateCaptcha = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let captcha = '';
    for (let i = 0; i < 6; i++) {
      captcha += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    setCaptcha(captcha);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (showCaptcha && captcha !== captchaInput) {
      setErrorMessage('CAPTCHA is incorrect. Please try again.');
      return;
    }
  
    try {
      const response = await fetch(`${apiUrl}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
  
      if (response.ok) {
        const responseData = await response.json();
        dispatch(login(responseData.accessToken)); 
        // Use StorageUtil to store token and user data
        setData('accessToken', responseData.accessToken);
        setData('refreshToken', responseData.refreshToken);
        setData('userData', responseData.userData);
        setShowSuccessPopup(true);
        setErrorMessage('');
      } else {
        const responseData = await response.json();
        if (responseData.message === 'Invalid credentials') {
          setErrorMessage('Login failed. Please check your email and password.');
        } else {
          setErrorMessage(responseData.message || 'An error occurred. Please try again later.');
        }
        setAttemptCount(attemptCount + 1);
      }
    } catch (error) {
      console.error('Error during login:', error);
      setErrorMessage('An error occurred. Please try again later.');
    }
  };

  const handleForgotPassword = () => {
    setIsForgotPassword(true);
    generateCaptcha();
    setErrorMessage('');
  };

  const handleBackToLogin = () => {
    setIsForgotPassword(false);
    setErrorMessage('');
  };

  const handleResetPassword = (e) => {
    e.preventDefault();
    if (captcha !== captchaInput) {
      setErrorMessage('CAPTCHA is incorrect. Please try again.');
      return;
    }
    if (email === '') {
      setErrorMessage('Please enter your email.');
    } else {
      // Here you can add the logic to send the reset password email.
      setShowSuccessPopup(true);
      setErrorMessage('');
    }
  };

  const closeSuccessPopup = () => {
    setShowSuccessPopup(false);
    setEmail('');
    setPassword('');
    setCaptchaInput('');
    if (!isForgotPassword) {
      window.location.href = '/dashboard';
    }
  };

  return (
    <div className="login-container">
      <div className="login-form-section">
        <h2>{isForgotPassword ? 'Forgot Password' : 'User Login'}</h2>
        <form onSubmit={isForgotPassword ? handleResetPassword : handleLogin} className="login-form">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          {!isForgotPassword && (
            <>
              <label htmlFor="password">Password:</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </>
          )}
          {(showCaptcha || isForgotPassword) && (
            <div className="captcha-container">
              <label htmlFor="captcha">CAPTCHA:</label>
              <div className="captcha-code">{captcha}</div>
              <input
                type="text"
                id="captcha"
                value={captchaInput}
                onChange={(e) => setCaptchaInput(e.target.value)}
                required
              />
            </div>
          )}
          <button type="submit" className="login-button">
            {isForgotPassword ? 'Reset Password' : 'Login'}
          </button>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          {!isForgotPassword && (
            <button type="button" onClick={handleForgotPassword} className="forgot-password-link">
              Forgot Password?
            </button>
          )}
          {isForgotPassword && (
            <button type="button" onClick={handleBackToLogin} className="back-button">
              &lt; Back
            </button>
          )}
        </form>
        {!isForgotPassword && (
          <div className="register-link">
            <p>Don't have an account? <Link to="/contact-us#form-section">Register</Link></p>
          </div>
        )}
      </div>
      {showSuccessPopup && (
        <div className="success-popup">
          <div className="success-message">
            <p style={{ color: 'black' }}>{isForgotPassword ? 'If account exists, you should have received an email with your password!' : 'Login successful!'}</p>
            <button onClick={closeSuccessPopup}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
