import { useCookies } from 'react-cookie';

export const StorageUtil = () => {
    const [cookies, setCookie, removeCookie] = useCookies();

  const setData = (key, value, storageType) => {
    try {
      if (typeof value !== 'string') {
        value = JSON.stringify(value);
      }
      switch (storageType) {
        case 'localStorage':
          localStorage.setItem(key, value);
          break;
        case 'sessionStorage':
          sessionStorage.setItem(key, value);
          break;
        case 'cookie':
          setCookie(key, value, { path: '/' });
          break;
        default:
          // If storageType is not specified, use cookie if consent is accepted, otherwise use localStorage
          if (getUserConsent()) {
            setCookie(key, value, { path: '/' });
          } else {
            localStorage.setItem(key, value);
          }
      }
    } catch (error) {
      console.error('Error setting data:', error);
    }
  };

  const getData = (key, storageType) => {
    try {
      let data;
      switch (storageType) {
        case 'localStorage':
          data = localStorage.getItem(key);
          break;
        case 'sessionStorage':
          data = sessionStorage.getItem(key);
          break;
        case 'cookie':
          data = cookies[key];
          break;
        default:
          // If storageType is not specified, use cookie if consent is accepted, otherwise use localStorage
          if (getUserConsent()) {
            data = cookies[key];
          } else {
            data = localStorage.getItem(key);
          }
      }
  
      // Check if data exists and is a string
      if (typeof data === 'string') {
        // Attempt to parse as JSON
        try {
          return JSON.parse(data);
        } catch (error) {
          // If parsing fails, return data as is
          return data;
        }
      } else {
        // If data is not a string, return it as is
        return data;
      }
    } catch (error) {
      console.error('Error getting data:', error);
      return null;
    }
  };
  

  const removeData = (key, storageType) => {
    try {
      switch (storageType) {
        case 'localStorage':
          localStorage.removeItem(key);
          break;
        case 'sessionStorage':
          sessionStorage.removeItem(key);
          break;
        case 'cookie':
          removeCookie(key, { path: '/' });
          break;
        default:
          // If storageType is not specified, use cookie if consent is accepted, otherwise use localStorage
          if (getUserConsent()) {
            removeCookie(key, { path: '/' });
          } else {
            localStorage.removeItem(key);
          }
      }
    } catch (error) {
      console.error('Error removing data:', error);
    }
  };

  const setUserConsent = (consent) => {
    try {
      localStorage.setItem('cookieConsentAccepted', consent);
    } catch (error) {
      console.error('Error setting user consent:', error);
    }
  };

  const getUserConsent = () => {
    try {
      return localStorage.getItem('cookieConsentAccepted') === 'true';
    } catch (error) {
      console.error('Error getting user consent:', error);
      return false;
    }
  };

  const checkCookieConsentGiven = () => {
    try {
      return localStorage.getItem('cookieConsentAccepted') !== null;
    } catch (error) {
      console.error('Error getting user consent:', error);
      return false;
    }
  };

  //Note: This function is on purpose not being used for website setup in AuthProvider, AuthGuard, authReducer, Header
  const isAuthenticated= () => {
    return localStorage.getItem('isAuthenticated') === 'true'
  };
  
  return { setData, getData, removeData, setUserConsent, getUserConsent, checkCookieConsentGiven, isAuthenticated };
};
