import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './ScrollIndicator.css';

const SCROLL_OFFSET = -100; // This value is based on header height for desired offset

function ScrollIndicator({ sections }) {
  const [currentSection, setCurrentSection] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (!sections || sections.length === 0) {
      console.warn('No sections provided to ScrollIndicator.');
      return;
    }

    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight / 2;
      let found = false;

      sections.forEach((section) => {
        const element = document.getElementById(section.id);
        if (element) {
          const { offsetTop, offsetHeight } = element;
          if (scrollPosition >= offsetTop && scrollPosition < offsetTop + offsetHeight) {
            setCurrentSection(section.id);
            found = true;
          }
        }
      });

      if (!found) {
        setCurrentSection(null);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [sections]);

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const sectionId = hash.substring(1);
      const section = sections.find(sec => sec.id === sectionId);
      if (section) {
        const element = document.getElementById(section.id);
        if (element) {
          const y = element.getBoundingClientRect().top + window.scrollY + SCROLL_OFFSET;
          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      }
    }
  }, [location, sections]);

  if (!sections || sections.length === 0) {
    return <div className="scroll-indicator">No sections available</div>;
  }

  return (
    <div className="scroll-indicator">
      {sections.map((section) => (
        <div
          key={section.id}
          onClick={() => {
            const element = document.getElementById(section.id);
            if (element) {
              const y = element.getBoundingClientRect().top + window.scrollY + SCROLL_OFFSET;
              window.scrollTo({ top: y, behavior: 'smooth' });
            }
          }}
          className={`scroll-item ${currentSection === section.id ? 'active' : ''}`}
        >
          {section.label}
        </div>
      ))}
    </div>
  );
}

export default ScrollIndicator;