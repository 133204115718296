import React from 'react';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import './PlaywrightCard.css';

const PlaywrightCard = ({ title, data }) => {
  const COLORS = ['#36A2EB', '#FF6384', '#FFCE56', '#4BC0C0'];
  const { totalTests, passedTests, failedTests, skippedTests, duration } = data;

  const pieData = [
    { name: 'Passed', value: passedTests },
    { name: 'Failed', value: failedTests },
    { name: 'Skipped', value: skippedTests }
  ];  

  return (
    <div className="card">
      <h3>{title}</h3>
      <div className="chart-container">
        <ResponsiveContainer width="100%" height={250}>
          <PieChart>
            <Pie data={pieData} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={80} fill="#8884d8" label>
              {pieData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div className="summary">
        {pieData.map((entry, index) => (
          <div className="summary-item" key={index}>
            <span className="color-box" style={{ backgroundColor: COLORS[index % COLORS.length] }}></span>
            {entry.name}: {entry.value}
          </div>
        ))}
      </div>
      <div className="duration">
        <strong>Test Duration:</strong> {duration} seconds
      </div>
    </div>
  );
};

export default PlaywrightCard;
