import React, { useState, useEffect } from 'react';
import './Reports.css';
import { useClientData } from '../../contexts/clientDataContext';

const Reports = () => {
  const { reports, clientProfile } = useClientData();
  const [localReports, setLocalReports] = useState(reports);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (clientProfile?.apiToken && clientProfile?.clientName) {
      fetchReports();
    }
  }, [clientProfile, apiUrl]);

  const fetchReports = async () => {
    try {
      const response = await fetch(`${apiUrl}/report/fetch/all`, {
        headers: { 'x-api-key': clientProfile.apiToken }
      });
      
      if (response.status === 404) {
        console.log('No reports found for this client.');
        setLocalReports({});
      } else if (response.ok) {
        const data = await response.json();
        setLocalReports(data);
      } else {
        console.error(`Error fetching reports: ${response.status} ${response.statusText}`);
        setLocalReports({});
      }
    } catch (error) {
      console.error('Error fetching reports:', error);
      setLocalReports({});
    }
  };

  const openReport = (reportName, format) => {
    const url = `${apiUrl}/report/fetch/${reportName}/${format}`;
    const options = {
      method: 'GET',
      headers: { 'x-api-key': clientProfile.apiToken }
    };
    fetch(url, options)
      .then(response => response.blob())
      .then(blob => {
        const newBlob = new Blob([blob], { type: format === 'json' ? 'application/json' : format === 'zip' ? 'application/zip' : 'text/html' });
        const newUrl = window.URL.createObjectURL(newBlob);
        window.open(newUrl, '_blank');
      })
      .catch(error => console.error('Error opening report:', error));
  };

  const handleToggle = (parentReport) => {
    const element = document.getElementById(parentReport);
    const countElement = document.getElementById(`count-${parentReport}`);
    if (element && countElement) {
      element.classList.toggle('collapsed');
      countElement.classList.toggle('hidden');
    }
  };

  return (
    <div className="full-page-container">
      <div className="reports-page-container">
        <div className="reports-header">
          <h1>Reports</h1>
        </div>
        <div className="reports-list">
          {Object.keys(localReports).length > 0 ? (
            Object.keys(localReports).map((parentReport) => (
              <div key={parentReport} className="parent-report">
                <div className="parent-report-header" onClick={() => handleToggle(parentReport)}>
                  <h2>{parentReport}</h2>
                  <span id={`count-${parentReport}`} className="report-count">
                    {Object.keys(localReports[parentReport]).length}
                  </span>
                </div>
                <div id={parentReport} className="parent-report-details">
                  {Object.keys(localReports[parentReport]).map((childKey) => {
                    const report = localReports[parentReport][childKey];
                    let lastUpdated = null;

                    // Determine last updated time
                    if (report.lastUpdated) {
                      lastUpdated = report.lastUpdated;
                    } else if (report.formats && report.formats.length > 0) {
                      lastUpdated = report.formats[0].lastUpdated; // Assuming first format's last updated
                    }

                    return (
                      <div key={childKey} className="child-report">
                        <div className="child-report-content">
                          <span>{childKey}</span>
                          <div className="report-buttons">
                            {report.formats && report.formats.map((format) => (
                              <button key={format} onClick={() => openReport(childKey, format)}>
                                {format.toUpperCase()}
                              </button>
                            ))}
                          </div>
                        </div>
                        <div className="report-timestamp">
                          Last Updated: {new Date(lastUpdated).toLocaleString()}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ))
          ) : (
            <div>No reports available</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Reports;